
import { Component, Vue, Watch } from 'vue-property-decorator'

import { api } from '@/services/api'
import { Alert } from '@/services/notification'

import { ConcursosService } from '@/services/concursosServices'

//components
import PortalAdminContent from '@/components/portalAdminContent2/PortalAdminContent2.vue'
import TextEditor2 from '@/components/textEditor2/TextEditor2.vue'
import MountFormExtraFields from '@/components/mountFormExtraFields/MountFormExtraFields.vue'

// services
import { getAllMapTiposDoc } from '@/services/documentosService'

import { 
  parseDateEnUs } from '@/utils';

//warppets
import { 
  wIcon,
  Breadcrumb
} from '@/components/wrappers';
import { isNull } from 'lodash'

// extrafields
import { extraFieldsConfig } from './extraFieldsConfig';

@Component({
  components: {
    wIcon,
    PortalAdminContent,
    Breadcrumb,
    TextEditor2,
    MountFormExtraFields
  }
})
export default class ConcursosId extends Vue {

  isUpdate = false

  defaultService = new ConcursosService(this.$store);
  extraFieldsConfig = extraFieldsConfig

  alert = new Alert(this.$store)
  isLoading = false
  isShowAnexar = false
  item: any = {
    descricao: '',
    status: 0,
    datacadastro: new Date()
  }
  isError = false;
  dropFiles: any = []
  anexos = []

  descricao = '';
  modalidadesSelect = [];
  situacoesSelect = [];

  arrTiposDocs = getAllMapTiposDoc()

  get titlePrincipal(){
    return getAllMapTiposDoc().find( (item: any) => {
      return item.id == this.$route.params.idTipo;
    })
  }


  mounted() {
    if( this.$route.params.idDocumento != undefined ){
      this.isUpdate = true;
      this.getId(this.$route.params.idDocumento);
    }
    else{
      this.isUpdate = false;
    }
  }

  getId(id: string){
    this.isLoading = true;
    const success = (response: any) => {
      console.log(response);
      
      this.item = this.defaultService.treatReceiveData(response.data);
      this.isError = false;
      this.getAnexos(id);
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    const url = '/documento/'+id;
    api.get(url)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  isExtraFields(){
    return this.item.fkdocumentotipo == '29';
  }

  clickBtnUpdateAnexo(item: any){
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success('Alterações salva com Sucesso')
      this.getAnexos(this.item.idDocumento);
      this.isError = false;
    }
    const error = (error: any) => {
      this.getAnexos(this.item.idDocumento);
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    const data = Object.assign({}, item);
    data.data_publicacao = parseDateEnUs(data.data_publicacao);
    // if(Object.keys(data.extrafields).length > 0){
    //   data.extrafields = JSON.stringify(data.extrafields);
    // }
    const url = '/documento/'+this.item.idDocumento+'/anexos/'+data.idDocumentoanexos;
    delete data.idLicitacaoanexo
    delete data.idDocumentoanexos
    // seletivoanexo
    delete data.arquivo
    api.put(url, data)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  getAnexos(id: string){
    this.isLoading = true;
    const success = (response: any) => {
      const anexos = response.data.list
      // this.anexos = response.data.list
      this.anexos = this.defaultService.processListAnexosReceive(anexos);
      this.isError = false;
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
    }
    const url = '/documento/'+id+'/anexos';
    api.get(url)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  changeDescription(content: string){
    this.item.descricao = content;
  }

  clickEnviarArquivos(){
    const data = this.mountFormData();
    this.sendPostAnexo(data);
  }

  mountFormData(){
    const data = new FormData();
    // this.dropFiles.map( (file: File, i: number) => {
    //   data.append('file'+i, file);
      
    // })
    data.append('file', this.dropFiles);
    data.append('titulo', 'Documento');
    // data.append('fktipo', '2');
    const dateNow = new Date();
    data.append('data_publicacao', parseDateEnUs(dateNow));
    data.append('status', '1');
    return data;
  }

  deleteDropFile(index: any) {
    this.dropFiles.splice(index, 1)
  }

  clearListDropFiles(){
    this.dropFiles = []
  }

  clickDeleteFile(item: any){
    const onConfirm = () => {
      this.sendDeleteFile(item.idDocumentoanexos)
    }
    this.alert.dialogConfirm(`Deseja deletar o arquivo?`, onConfirm)
  }

  sendDeleteFile(idFile: any){
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success('Arquivo Deletado com Sucesso')
      this.getAnexos(this.item.idDocumento);
      this.isError = false;
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    const url = '/documento/'+this.item.idDocumento+'/anexos/'+idFile;
    api.delete(url)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }


  clickDeleteItem(){
    const onConfirm = () => {
      this.sendDelete();
    }
    this.alert.dialogConfirm('Deseja continuar com esta operação?', onConfirm)
  }

  sendPostAnexo(data: any){
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success('Anexo Adicionado com Sucesso')
      this.clearListDropFiles();
      this.getAnexos(this.item.idDocumento);
      this.isError = false;
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    const url = '/documento/'+this.item.idDocumento+'/anexos';
    api.post(url, data)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  sendDelete(){
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success('Item Deletado com Sucesso')
      this.isError = false;
      this.$router.push(`/tipo-documento/${this.$route.params.idTipo}`)
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    const url = '/documento/'+this.item.idDocumento;
    api.delete(url)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  clickBtnSalvar(){
    if( this.isUpdate ){
      this.sendUpdate()
    }
    else{
      this.sendPost()
    }
  }

  sendPost(){
    this.isLoading = true;
    const success = (response: any) => {
      const url =  `/tipo-documento/${this.$route.params.idTipo}/documento/${response.data.idDocumento}`;
      this.$router.push(url)
      this.alert.success('Item Criadao com Sucesso')
      this.isError = false;
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    let data = Object.assign({}, this.item);
    data.fkdocumentotipo = this.$route.params.idTipo;
    for(const key in data){
      if( data[key] == ''){
        delete data[key]
      }
    }
    data = this.defaultService.treatToSendData(data);
    const url = '/documento';
    api.post(url, data)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  @Watch('$route', { 
    immediate: false, deep: true 
  })
  changeRoute(){
    if( this.$route.params.idDocumento != undefined ){
      this.isUpdate = true;
      this.getId(this.$route.params.idDocumento);
    }
    else{
      this.isUpdate = false;
    }
    window.scrollTo(0, 0);
  }

  sendUpdate(){
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success('Item Salvo com Sucesso')
      this.isError = false;
    }
    const error = (error: any) => {
      this.alert.notification('is-danger' ,error?.response?.data?.errors[0].msg)
      // this.isError = true;
    }
    let data = Object.assign({}, this.item);
    delete data.idDocumento;
    delete data.fkdocumentotipo;
    data = this.defaultService.treatToSendData(data);
    
    for(const key in data){
      if( data[key] == '' || isNull(data[key]) ){
        delete data[key]
      }
    }
    // return;
    const url = '/documento/'+this.item.idDocumento;
    api.put(url, data)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  getFileName(file: any){
    if( file.nomearquivo == null ){
      let arr = file.arquivo;
      arr = arr.split('/')
      return arr[(arr.length-1)]
    }
    else{
      return file.nomearquivo
    }
  }

  getFileLocation(file: any){
    let arr = file.arquivo;
    arr = arr.split('/')
    if( arr[2] == 'apitransparencia2.laranjadaterra.es.gov.br' ){
      return 'http://apitransparencia2.laranjadaterra.es.gov.br'+file.arquivo
    }
    if( arr[1] == 'uploads' &&  arr[2] == 'documento' ){
      return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
    }
    if( arr[1] == 'novosUploads' &&  arr[2] == 'processosSeletivos' ){
      return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
    }
    return file.arquivo
  }
  

}
