
import type { FiedlsConfig } from '@/components/mountFormExtraFields/mountFormExtraFields'


/*
Data da publicação

Número/contrato de repasse
Ano
Período de vigência

Convenente
Proponente
Objeto

Situação andamento/finalizado
Valor
Data do primeiro repasse
*/

export const convenios: FiedlsConfig = [
  [
    {
      field: 'contrato',
      label: 'Número/contrato de repasse',
      size: 4,
      type: 'text',
    },
    {
      field: 'objeto',
      label: 'Objeto',
      size: 4,
      type: 'text',
    },
    {
      field: 'ano',
      label: 'Ano',
      size: 4,
      type: 'mask',
      mask: '####'
    },
  ],
  [
    {
      field: 'vigencia',
      label: 'Período de vigência',
      size: 4,
      type: 'text',
    },
    {
      field: 'convenente',
      label: 'Convenente',
      size: 4,
      type: 'text',
    },
    {
      field: 'proponente',
      label: 'Proponente',
      size: 4,
      type: 'text',
    },
  ],
  [
    {
      field: 'situacao',
      label: 'Situação',
      size: 4,
      type: 'select',
      options: [
        {
          label: 'Em andamento',
          value: 'Em andamento'
        },
        {
          label: 'Finalizado',
          value: 'Finalizado'
        }
      ]
    },
    {
      field: 'valor',
      label: 'Valor',
      size: 4,
      type: 'money',
    },
    {
      field: 'dataPrimeiroRepasse',
      label: 'Data do primeiro repasse',
      size: 4,
      type: 'mask',
      mask: '##/##/####'
    },
  ]
]

export const extraFieldsConfig = {
  '29': convenios
}

