import { Component, Vue, Prop } from 'vue-property-decorator'
import { Money } from 'v-money'

export type SelectOptions = {
  label: string;
  value: string | number;
}

export type FieldConfig = {
  label: string;
  field: string;
  size?: string | number;
  type?: string;
  options?: Array<SelectOptions>;
  mask?: string | Array<string>;
}

export type FormRowCofig = Array<FieldConfig>

export type FiedlsConfig = Array<FormRowCofig>

@Component({
  components: {
    Money
  }
})
export default class MountFormExtraFields extends Vue {
  @Prop() fieldsConfig!: FiedlsConfig;
  @Prop() model!: any;

  moneyConfig = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    suffix: '',
    precision: 2,
    masked: true
  }
  
}
  